<template>

    <div>
        Anzahl Wohnungen: {{totalUnits}}
    </div>

</template>

<script>
    import V from 'voUtils/V.js'
    import {
    	doReq
    }
    from 'voUtils/RestEx.js';


    export default {
    	voVueComponent: 'facility-unit-count',

    	props: {
    		facilityid: String,
    		totalUnits: Number
    	},
    	data() {
    		return {
    			totalUnits: Number,
    		}
    	},

    	mounted: function() {
    		var resultFields = {
    			'u._id': {
    				'fieled': '_id'
    			}
    		};
    		var req = {
    			fields: resultFields,
    			filter: {
    				facility: parseInt(this.facilityid, 10)
    			},
    			max: 1000
    		};
    		doReq('searchUnits', req)
    			.then((res) => {
    				this.totalUnits = res.total;
    			});
    	},

    	methods: {}

    };
</script>