var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("span", {
        staticClass: "price-start",
        domProps: { innerHTML: _vm._s(_vm.getPriceText) }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "more-price-info" }),
      _vm._v(" "),
      _c("price-help")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }