<template>
    <a href="javascript:0;"
        data-toggle="popover"
        :data-trigger="getTrigger"
        data-placement="top"
        data-html="true"
        :data-content="getPriceText">
        <i class="fa fa-info-circle"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'price-help',
    	props: {
    		pricelist: Array
    	},
    	mounted: function() {
    		$('[data-toggle="popover"]').popover({
    			placement: 'top',
    			html: true,
    			title: 'User Info'
    		});
    		$(document).on("click", ".popover .closepopover", function() {
    			$(this).parents(".popover").popover('hide');
    		});

    	},
    	computed: {
    		getTrigger: function() {
    			// check for common mobile user agents
    			if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
    				return 'click';
    			}
    			return 'hover';

    		},
    		getTitleText: function() {
    			var titleText = '';
    			if (this.pricelist) {
    				titleText = 'Obligatorische Nebenkosten';
    			} else {
    				titleText = 'Zusätzliche Kosten';
    			}
    			return titleText;
    		},
    		getPriceText: function() {
    			var priceText = '<strong>In Nebenkosten enthalten</strong><br>Endreinigung<br>Buchungsgebühr<br>Küchen-Starterset<br>Bad-Starterset<br>';
    			if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
    				priceText += '<div class="closepopover" data-dismiss="alert">Schließen</div>';
    			}


    			return priceText;
    		}
    	}
    };
</script>